/* loading screen */
.loading-container {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
/* notfound img */
.notfound-img{
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
}
/* table headers */
th[class*="column_"].ant-table-cell{
  height: 60px;
}
/* filter icon header */
.blue-icon{
  filter: brightness(0) saturate(100%) invert(33%) sepia(99%) saturate(1049%) hue-rotate(182deg) brightness(108%) contrast(99%);
}
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?f1z7ng');
  src:  url('fonts/icomoon.eot?f1z7ng#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?f1z7ng') format('truetype'),
  url('fonts/icomoon.woff?f1z7ng') format('woff'),
  url('fonts/icomoon.svg?f1z7ng#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Others{
  font-size: 24px;
  color: white !important;
}
.icon-Others .path1:before {
  content: "\e900";
  color: #000;
}
.icon-Others .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(2, 137, 208);
  background-color: white !important;
}
.icon-Others .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-Fr{
  font-size: 24px;
  color: white !important;
}
.icon-Fr .path1:before {
  content: "\e903";
  color: #000;
}
.icon-Fr .path2:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(0, 82, 180);
  background-color: white !important;
}
.icon-Fr .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(245, 245, 245);
}
.icon-Fr .path4:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.icon-Eng{
  font-size: 24px;
  color: white !important;
}
.icon-Eng .path1:before {
  content: "\e907";
  color: #000;
}
.icon-Eng .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: white !important;
  background-color: white !important;
}
.icon-Eng .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(216, 0, 39);
}
.icon-Eng .path4:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(0, 82, 180);
}
.icon-Eng .path5:before {
  content: "\e90b";
  margin-left: -1em;
  color: white !important;
}
.icon-Intranet_securise:before {
  content: "\e90c";
  font-size: 18px;
}
.icon-Intranet:before {
  content: "\e90d";
  font-size: 18px;
}
.icon-Internet_securise:before {
  content: "\e90e";
  font-size: 18px;
}
.icon-Internet:before {
  content: "\e90f";
  font-size: 18px;
}
.icon-rich_media:before {
  content: "\e910";
  font-size: 18px;
}
.icon-v01:before {
  content: "\e911";
  color: #d7d7d7;
  font-size: 350px ;
  margin-top: 10px;
}
.copy-icon.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.copy-icon.clicked {
  fill: red;
}
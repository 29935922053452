@import "~antd/dist/antd.css";
@font-face {
  src: url(assests/fonts/transfonter.org-20210601-105009/SFProText-Medium.eot);
  font-family: SFPro;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Regular.eot)
      format("embedded-opentype"),
    url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Regular.woff2)
      format("woff2"),
    url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Regular.woff)
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Medium.eot)
      format("embedded-opentype"),
    url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Medium.woff2)
      format("woff2"),
    url(assests/fonts/transfonter.org-20210601-105009/SFProDisplay-Medium.woff)
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "SF Pro Display" !important;
  font-weight: 400;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-content::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.15) !important;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.ant-table-content table {
  overflow: visible !important;
}
.App {
  margin: 16px;
}
.row-evenements-navigation {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}
.col1-evevnt {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.div-navigation-evenements {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.div-navigation-evenements span {
  font-size: 14px;
  color: #000000;
}

.row-evenements-navigation {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}
#content-mobile {
  display: none !important;
}
.EditAction,
.DeleteAction,
.LinkAction,
.StatisticAction {
  display: none !important;
}

.col1-evevnt {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.div-navigation-evenements {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.div-navigation-evenements span {
  font-size: 14px;
  color: #000000;
}
.row-event {
  width: 100%;
  height: 58px;
  background-color: #f5f5f5;
  padding: 0 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.col1-row-event {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.col2-row-event {
  width: 55%;
}
.text-event {
  font-family: "SF Pro Display";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.message-event {
  animation: animate 1s ease-in;
}
.col-search {
  width: 60%;
  height: 40px;
}
.col-calender {
  width: 40%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center !important;
}
.col-delete-elements {
  width: 100%;
  margin-bottom: 0.5%;
}
.input-search {
  width: 100%;
  height: 40px !important;
  padding: 0 !important;
}
.row-action {
  justify-content: space-between;
  margin: 1em 0;
  display: flex !important;
  flex-wrap: nowrap !important;
}
.calendar {
  width: 40%;
  height: 40px !important;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden !important;
}
.div_filter_intranet_internet {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 10px;
}

 
.div_dropdown_diffusion {
  width: 40%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}
.div_dropdown_diffusion .ant-dropdown-trigger {
  height: 40px;
}

.tooltip_intranet {
  width: 35%;
  height: 100%;
  cursor: pointer;
}
.tooltip_delete {
  width: 35%;
  height: 100%;
  cursor: pointer;
  margin-right: 5%;
}
.tooltip_internet {
  width: 35%;
  height: 100%;
  cursor: pointer;
  margin-left: 5%;
}
.btn_internet_filter,
.btn_internet_filter {
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5% !important;
}

.btn_delete,
.btn_delete {
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5% !important;
}


#icon_internet_filter:before,
#icon_intranet_filter:before {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.65) !important;
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
.icon_calender {
  width: 14px;
  height: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-right: 5px;
}
.div-delete-elements {
  width: 95%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.div-delete-elements Button {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
.icon_delete {
  font-size: 20px !important;
}
.div-delete-elements span {
  margin-right: 5px;
}
.emp {
  justify-content: space-between;
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  align-items: center;
  padding: 0 1.5em;
}
.title {
  font-family: "SF Pro Display";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 10px;
}
.row-tabPane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bloc-form {
  width: 49%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}
.input-form {
  display: flex !important;
  flex-direction: column !important;
  text-align: left;
}
.require {
  color: #ff4d4f;
  margin-left: 2px;
}

.fileListImage {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover; 
  border: 1px solid #ccc; 
  padding: 5px;
  margin: 5px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 

}


.label {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0.5em;
  text-align: left;
}

.date-picker {
  display: flex;
  flex-direction: row;
}
.col-Password_Intranet {
  left: 8%;
}
.col-Password_Internet {
  left: 8%;
}
.col-Password_Internet .error {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}
.col-Password_Intranet .error {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}

.hidden-error {
  display: none !important;
}

.input-form-date {
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}
.checkbox {
  margin: 5px 5px;
  text-align: left;
  width: 100%;
}
.colTabPane {
  width: 50%;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  padding: 2em;
}
.space_orateur {
  width: 50px !important;
  height: 32px;
  font-size: 20px !important;
  color: #000000;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  align-items: center !important;
  text-align: center !important;
  margin: 0 5px 0px 0px;
  padding: 4px !important;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
}
.button-add_language {
  height: 32px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 30px;
  background: #fff;
}
.button-add_language:hover {
  border: solid 1px #3ea8ff;
}
.button-add_language:hover .add_icon_lang {
  color: #3ea8ff;
}
.filter-row {
  width: 100%;
}

.col-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.div-filter {
  width: 60%;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: absolute;
  z-index: 9;
  overflow: hidden !important;
}
.div-select {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2%;
}
.select1,
.select2 {
  width: 30%;
  height: 32px;
  background-color: #ffffff !important;
  text-align: left !important;
}
.datePicker {
  width: 220px;
}
.filterIcon:hover {
  color: #1890ff !important;
}
#hover {
  color: #1890ff !important;
  overflow: hidden !important;
}

.select1 Option,
.select2 Option {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}
#option_select {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.div-button-filter {
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 2%;
}
.btn_renitialiser {
  width: 107.76px;
  height: 32px;
}

.exportRow {
  display: flex;
  justify-content: flex-end;
  text-align: end;
  padding-bottom: 10px;
}
.exportRow .export{
  display: flex;
  justify-content: flex-end;
}
.span_rangepicker {
  align-items: right;
  margin-right: 5px;
  margin-left: 35px;
}
.spinning-btn{
  display: inline-block;
  width: 95px !important;
}
.btn_filtrer {
  width: 69.91px;
  height: 32px;
  margin-left: 5%;
}
.div_apercu_image {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.img_apercu_principale {
  margin-right: 5px;
}
.div_apercu_image Button {
  margin-right: 5px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.div_apercu_image Button img {
  margin-left: -7.5px;
  margin-top: -7.5px;
  width: 13.5px;
  height: 13.5px;
}
.span_supprimer {
  font-size: 14px;
  font-weight: 400;
  line-height: 22;
  color: rgba(0, 0, 0, 0.85);
}

.ant-pagination-options {
  position: absolute;
  left: 0;
}
.ant-table-pagination.ant-pagination {
  position: relative;
}
.check-language {
  text-align: left;
}
.select {
  width: 200px !important;
}
.pwd {
  font-size: 10px;
  width: 100%;
  padding-bottom: 1em;
}
.pwd_border .ant-input:focus,
.ant-input:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover {
  box-shadow: red !important;
}
.ant-form-item-explain {
  font-size: 12px !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  /* padding: 10px 10px !important; */
}
.all {
  width: 100%;
}
.div_apercu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 8;
}
.div_apercu .ant-image-img {
  display: block;
  position: relative;
  width: 93px;
  height: 54px;
}
.div_titre {
  min-height: 10px;
  text-align: left !important;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 80%;
}
.ant-table-selection-column {
  width: 48px !important;
}
.div_apercu .ant-image-mask {
  display: none !important;
}
.div_infos_aperçu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5%;
  z-index: 7 !important;
  width: 60%;
  flex-wrap: wrap;
}
.div_tooltip_action {
  display: flex;
  justify-content: space-between;
}
.ant-space-item:focus .ant-dropdown-placement-bottomLeft {
  display: block !important;
}
.btn_apercu {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  overflow: hidden;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.img-apercu {
  margin-right: 5px;
  width: 93px;
  height: 54px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.div_img_vide {
  margin-right: 5px;
  width: 93px;
  height: 54px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.img-vide {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.65) !important;
}
#img_log {
  color: rgba(0, 0, 0, 0.65);
}
.div_spinner {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.spinner_table {
  margin-top: 200px;
}
.tabs-content_tabPane {
  color: red;
}
.icon_info {
  font-size: 35px;
  color: #40a9ff !important;
}
.Modal_delete {
  text-align: center;
}
.div_text_modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90px;
}
.spn_delete_1 {
  font-size: 25px;
  color: black;
}
.spn_delete_2 {
  color: #8d8d8d;
}
.div_alert {
  position: fixed;
  top: 4% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}
.div_alert_DataTable {
  position: fixed;
  top: 2% !important;
  left: 40%;
  z-index: 2000;
}
.div_alert_lang {
  position: fixed;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
}
#ant-alert_lang {
  width: 540px !important;
  height: 40px !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65) !important;
  z-index: 2000;
}
#ant-alert {
  width: 540px !important;
  height: 40px !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65) !important;
  z-index: 2000;
}
#ant-alert_DataTable {
  width: 540px !important;
  height: 40px !important;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65) !important;
  z-index: 2000;
}

.btn_annuler {
  color: rgb(24, 144, 255) !important;
  font-size: 14px;
  font-weight: 400;
}
.table_alert {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table_alert tbody.ant-table-tbody{
  overflow-y: scroll;
}
.ant-table-body{
  height: 65vh;
}
.ant-table {
  width: 100% !important;
}

.timepicker {
  text-align: left;
}
.btn_stop {
  text-align: left;
  margin: 0.8% 0%;
}
.div_radio_button {
  height: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}
.div_checkbox {
  height: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
#check_box {
  font-size: 8px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.p_export {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  margin: 16px 101px 16px 1px;
}
.div_internet_intranet {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.hr_div {
  height: 40px;
  width: 70%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.div_internet_intranet Button {
  height: 100%;
  width: 15%;
  padding: 9px 16px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.02);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.div_internet_intranet Button:nth-of-type(1) {
  margin-right: 2px;
}
#active {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #1890ff;
  border-bottom: 0px solid;
  background-color: #ffffff;
}
.btn_internet_modal:focus,
.btn_intranet_modal:focus {
  border: solid 1px rgba(0, 0, 0, 0.15) !important;
  border-bottom: 0px solid !important;
}

.div_permalien {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.div_dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}

.div_dropdown Button {
  display: flex;
  align-items: center;
  padding: 5px;
}
.title_span{
 margin-left: 10px;

}
.div_dropdown span {
  font-weight: bold; 
}

.div_dropdown span:last-child {
  margin-left: 20px;
}
.ant-select-selection-item {
  margin-left: 0px !important;
}
.div_permalien h5 {
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.div_permalien button {
  width: 64px;
  height: 24px;
  padding: 1px 8px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}

.input_permalien {
  width: 100%;
  margin-bottom: 16px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.65) !important;
  white-space:nowrap ;
  overflow:hidden;
  text-overflow: ellipsis;
}
.div_lienIntegration {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.div_lienIntegration h5 {
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}
.div_lienIntegration button {
  width: 64px;
  height: 24px;
  padding: 1px 8px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.text_area {
  min-height: 84px !important;
  padding: 8px 14px 9px 12px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.65) !important;
  margin-bottom: 33px;
}
.btn_fermer {
  width: 78px;
  height: 32px;
  padding: 5px 16px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.57 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-modal-title {
  margin-left: 5px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
.link_icon {
  position: absolute;
  top: 17px;
  left: 35px;
  font-size: 18px !important;
}
.custom-modal-title{
  padding: 0 43px;
}
.selekton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.ant-checkbox-group {
  width: 100% !important;
}
.div_lang_space {
  height: 100% !important;
  width: 100% !important;
  display: flex;
}
.oooo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 30000;
  background-color: #ff4d4f;
  padding: 20%;
}
.IconDeleteMultiple {
  color: red !important;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.ant-form-item-control-input {
  min-height: 2px !important;
}
.row1_statistic {
  width: 100% !important;
}
.col_row1_statistic {
  width: 24%;
  margin-right: 1%;
}
.chartCardstatistic {
  text-align: left;
}
.row2_statistic {
  width: 100% !important;
}
.col_row2_statistic {
  width: 48%;
  margin-right: 2%;
  padding: 2%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.span_col_row2_statistic {
  width: 100% !important;
  padding: 1%;
  margin-bottom: 2%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.row_titre_evenement {
  margin-top: 20px !important;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.col_titre_evenement {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.div_titre_evenement {
  margin-left: 2.5%;
  height: 60%;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 145, 255);
  border-bottom: 2px solid rgb(24, 144, 255);
}

.ant-tooltip ant-tooltip-placement-top ant-tooltip-hidden {
  visibility: hidden !important;
}

.ant-tooltip ant-tooltip-placement-top ant-tooltip-hidden {
  display: none !important;
}
.drp {
  background-color: black !important;
}
#activeFilter {
  color: #40a9ff;
}
.div_cvcv {
  background-color: #ff4d4f;
  padding: 5%;
}
.btn_show_action {
  display: none !important;
}
.div_btn_show_action {
  display: none !important;
}
.div_show_Action_Menu {
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none;
}
.div_fr {
  width: 100% !important;
}
.audio {
  font-size: 20px !important;
}
.select_or {
  text-align: left !important;
  padding: 0 !important;
}
.option {
  width: 130px !important;
}
.ant-select-dropdown-placement-bottomLeft {
  width: 130px !important;
}
.Default_Page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Default_Page span {
  font-size: 80px;
  font-weight: 800;
  text-align: center;
  font-family: "SF Pro Display" !important;
}
.Default_Page h3 {
  font-size: 25px;
  text-align: center;
  font-family: "SF Pro Display" !important;
  margin-top: -40px;
}
.Default_Page p {
  text-align: center;
  font-family: "SF Pro Display" !important;
  font-size: 12px;
}

/* Media Query */
@media (min-width: 1280px) and (max-width: 1380px) {
  .select1,
  .select2 {
    width: 180px;
    height: 32px;
    background-color: #ffffff !important;
    margin-right: 10px !important;
  }
  .datePicker {
    width: 180px !important;
    margin-left: 10px !important;
  }
}
@media (min-width: 801px) and (max-width: 1280px) {
  .div-filter {
    padding: 0 5px;
  }
  .select1,
  .select2 {
    width: 220px;
    height: 32px;
    background-color: #ffffff !important;
  }
  .datePicker {
    width: 220px !important;
  }
  .btn_renitialiser,
  .btn_filtrer {
    width: 100px;
    text-align: center;
    font-size: 10px;
    margin-right: 5px;
  }
  .col-search {
    width: 100%;
    margin-left: 0.5em;
  }
  .filter-row {
    width: 100%;
  }
  .col-filter {
    width: 100%;
  }
  .div-filter {
    width: 100%;
  }
  .col-delete-elements {
    width: 50%;
  }

  .col-calender {
    width: 50% !important;
    height: 10% !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .calendar {
    padding: 0% !important;
    width: 65% !important;
  }
  .div_filter_intranet_internet {
    width: 35% !important;
  }

  #icon_internet_filter:before,
  #icon_intranet_filter:before {
    font-size: 30px !important;
  }
  /* Alert Delete */
  .div_alert {
    position: fixed;
    top: 2%;
    left: 42.5%;
    z-index: 2000;
  }
  .div_alert_DataTable {
    position: fixed;
    top: 2% !important;
    left: 27%;
    z-index: 2000;
  }
  #ant-alert {
    width: 540px !important;
    height: 40px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
  #ant-alert_DataTable {
    width: 540px !important;
    height: 40px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }

  .btn_annuler {
    color: rgb(24, 144, 255) !important;
    font-size: 14px;
    font-weight: 400;
  }
  .div_alert_lang {
    position: fixed;
    top: 3%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 2000;
  }
  #ant-alert_lang {
    width: 540px !important;
    height: 40px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
}
@media (max-width: 800px) {
  .row-evenements-navigation {
    width: 100% !important;
  }
  .row-action {
    width: 100% !important;
  }
  .col-search {
    width: 100% !important;
  }
  .header-event {
    width: 100% !important;
  }
  .table_alert {
    width: 100% !important;
  }
  .ant-table {
    width: 100% !important;
  }
  .div-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
#root {
  width: 100%;
  overflow: hidden;
}
.ant-table-tbody > tr > td {
  padding: 0.5em 0 !important;
}
.column_Id{
  min-width: 50px;
}
@media (max-width: 768px) {
  .showEvent {
    width: 100%;
  }
  .row-action {
    margin: 1em 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .header-event {
    min-width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 1em;
  }
  .table_alert {
    width: 100% !important;
  }
  .ant-table {
    width: 100% !important;
  }
  .col1-row-event {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .col2-row-event {
    width: 100%;
  }
  
  .div_dropdown_diffusion {
    width: 40% !important;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 !important;
  }
  .div_dropdown_diffusion .ant-dropdown-trigger {
    padding: 0 5% !important;
  }
  .div_dropdown_diffusion button {
    width: 100% !important;
  }
  .div_filter_intranet_internet {
    margin: 0 !important;
  }
  .calendar {
    margin: 0 3% !important;
  }

  .row-event {
    padding: 0 5px !important;
  }
  .col1-row-event {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .col-search {
    width: 100% !important;
  }
  .col-calender {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    margin-bottom: 0.75em;
  }
  .ant-row {
    width: 100% !important;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .ant-table-selection-column {
    display: none !important;
  }
  .div_apercu {
    margin: 0;
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .div_apercu .ant-image-img {
    display: block;
    margin: 0;
    width: 145px;
    height: 86px;
  }
  .div_infos_aperçu {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding-top: 5px;
  }
  .ant-table-tbody > tr > td {
    padding: 0.5em !important;
  }
  .div_titre {
    min-height: 10px;
    text-align: left !important;
    font-weight: 500;
    font-size: 13px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
    -webkit-line-clamp: 1;
    height: 25px;
    white-space: nowrap;
  }
  .ant-table-content {
    width: 100% !important;
    overflow-y: hidden;
    overflow-x: scroll;
  }
  .ant-table-content table {
    min-width: 510px;
  }
  table tr:not(:last-child) {
    margin-bottom: 2%;
  }
  .column_apercu {
    order: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    border-bottom: none !important;
    border-right: none !important;
  }
  .column_title {
    order: 2;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100% !important;
    max-width: 150px;
    border-bottom: none !important;
    border-right: none !important;
    display: flex;
    align-items: center;
  }
  .div_titre{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .column_date {
    order: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    border-bottom: none !important;
    border-right: none !important;
    display: flex;
  }
  .column_lang {
    order: 4;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    border-bottom: none !important;
    border-right: none !important;
  }
  .column_Id {
    display: none !important;
  }

  .div_lang_space .ant-space-item .anticon-down {
    display: none !important;
  }
  .div_lang_space button {
    width: 35px !important;
  }
  #content-mobile {
    display: flex !important;
    justify-content: flex-end;
    flex-direction: row;
  }
  #content-mobile button {
    border: none !important;
    background: none;
  }
  #content-mobile .anticon-ellipsis {
    font-size: 22px !important;
  }
  .ant-dropdown-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-left: 5px !important;
  }
  .EditAction,
  .DeleteAction,
  .LinkAction,
  .column_lang .ant-space-item {
    margin-right: 0 !important;
  }
  .div-filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 170px;
    padding: 5px;
  }
  .div-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 120px;
    padding: 0;
  }
  .div-select div {
    width: 100%;
  }
  .div-button-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  .row1_statistic {
    display: flex !important;
    flex-direction: column !important;
  }
  .col_row1_statistic {
    width: 100%;
  }
  .row2_statistic {
    display: flex !important;
    flex-direction: column !important;
  }
  .col_row2_statistic {
    width: 100%;
  }
  .calendrier {
    width: 100%;
  }
  /*Formulaire*/
  .emp {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .emp-col {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 16px;
  }
  .row_checkbox {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
  }
  .drp_langue {
    height: 20px !important;
    width: 100% !important;
  }
  .select_langue {
    width: 150px !important;
  }
  .row_timepicker {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .row_timepicker_title {
    width: 55% !important;
    justify-content: space-between !important;
  }
  .row_timepicker .ant-col-6 {
    max-width: none !important;
  }
  .date_debut {
    width: 85px !important;
  }

  .date_fin {
    width: 85px !important;
  }
  .btn_stop {
    margin: 0;
  }
  .row_infos {
    display: flex;
    flex-direction: column !important;
  }
  /* Alert Delete */
  .div_alert_DataTable {
    position: fixed;
    top: 6% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    width: 350px !important;
  }
  #ant-alert_DataTable {
    width: 540px !important;
    height: 40px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
  .btn_annuler {
    color: rgb(24, 144, 255) !important;
    font-size: 14px;
    font-weight: 400;
  }
  .div_alert_lang {
    position: fixed;
    top: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    width: 350px !important;
  }
  #ant-alert_lang {
    width: 540px !important;
    height: 30px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
  .icon-Others {
    font-size: 20px !important;
  }
  .icon-Fr {
    font-size: 20px !important;
  }
  .icon-Eng {
    font-size: 20px !important;
  }
  .audio {
    font-size: 20px !important;
  }
 
  .row-tabPane {
    display: flex;
    flex-direction: column !important;
  }
  .bloc-form,
  .colTabPane {
    width: 100% !important;
    padding: 1em;
  }
  .bloc-form-div {
    margin-top: 2%;
  }
  /* Alert Delete */
  .div_alert_DataTable {
    position: fixed;
    top: 2% !important;
    left: 25%;
    z-index: 2000;
  }
  #ant-alert_DataTable {
    width: 540px !important;
    height: 40px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
  .btn_annuler {
    color: rgb(24, 144, 255) !important;
    font-size: 14px;
    font-weight: 400;
  }
  .div_alert_lang {
    position: fixed;
    top: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    width: 400px !important;
  }
  #ant-alert_lang {
    width: 540px !important;
    height: 30px !important;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65) !important;
    z-index: 2000;
  }
  .ant-card-body {
    padding: 0 !important;
  }
  .col-Password_Intranet {
    width: 90% !important;
  }
  .col-Password_Internet {
    width: 90% !important;
  }
  .ant-divider-horizontal {
    display: none !important;
  }
  .ant-tabs-tab {
    padding: 12px !important;
  }
  .ant-card-bordered {
    border: none !important;
  }
  /* datepicker */
  .ant-picker-ranges{
    display: flex;
  }
}

@media screen and (max-width: 423px){
  .btn_add_event span:last-child {
    display: none;
  }
  .btn_Update :nth-child(2) {
    display: none !important;
  }
}
.MenuAction{
  display: flex;
  flex-wrap: wrap;
 
}
.isYoutube{
  margin-left: 32px;
}

.menu{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) and (max-width: 768px) {
  .col1-row-event {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .div_apercu {
    flex-direction: row;
  }
  .div_infos_aperçu {
    position: absolute;
    left: calc(145px + 1.5em);
    top: 33px;
  }
  .column_apercu {
    order: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    border-bottom: none !important;
    border-right: none !important;
  }
  .column_title {
    order: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100% !important;
    border-bottom: none !important;
  }
  .column_date {
    order: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    border-bottom: none !important;
    display: flex;
    align-items: center;
  }
  .column_lang {
    order: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    border-bottom: none !important;
    border-right: none !important;
  }
  .ant-pagination {
    width: 100%;
  }
  .emp {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
  }
  .col-Password_Intranet {
    width: 90% !important;
  }
  .col-Password_Internet {
    width: 90% !important;
  }
  .emp-col_buttons {
    justify-content: flex-end;
  }
  .emp-col_title {
    justify-content: flex-start;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .tabs-list {
    width: 95%;
    display: flex;
    align-self: center;
  }
}
.accessText{
  color: #bebbbb !important;
}

/* ************************* Media query**************************** */

@media screen and (max-width:800px){
  .span_rangepicker {
    align-items: right;
    margin-right: 5px;
   margin-left: 0 !important; 
    width: 100% !important;
}
  
}  

@media screen and (max-width:770px){
  .emp{
    display: grid !important;
    flex-direction: row !important;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr;
    height: 58px !important;
    padding-inline: 0 .5em;
  }
  .ant-table-body > table{
    table-layout: auto !important;
    width: 100%;
    max-width: 100%;

  }
  .ant-table-body > table{
    table-layout: auto !important;
    width: 100%;
    max-width: 100%;

  }
 .label{
    display: flex !important;
    flex-wrap: wrap !important;
    min-width: 120px !important;
  }
  /* .column_title {
    max-width: 100px;
  } */
  .column_lang{
    min-width: 150px;
  }
  .column_action{
    min-width: 100px;
  }

}

@media screen and (max-width:600px) {
 .column_title {
    max-width: 120px;
  }
  .column_lang{
    min-width: 120px;
  }
  .column_action{
    min-width: 120px;
    margin:0 auto;
  }
 
}
@media screen and (max-width:580px) {
  .emp .ant-tabs-tab {
    padding: 10px !important;
   
  }
  .ant-tabs-tab > *{
    overflow:hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  max-width: 140px;
  }
  .ant-tabs-tabpane {
    font-size: 12px !important;
}
 .ant-tabs-nav-wrap{
    position: relative;
    display: inline-block;
    display: inline-block !important;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 100% !important;
  }
  .moreIcon{
    margin:auto;
  }
  
}
.menu{
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  max-width: 100%;
}
  .menu > *{
    /* display: inline !important; */
    font-size:20px !important;
    margin:0.2em !important;
    color: rgba(0, 0, 0, 0.64);

  }
  @media screen and (max-width:500px){
    .ant-tabs-tab > *{
      font-size:14px !important;
      overflow:hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    max-width: 120px;
    }
    .column_lang{
      min-width: 84px;
    }
    /* .column_action{
      min-width: 100px;
      margin:0 auto;
    } */
  }


@media screen and (max-width:480px){
   .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
}
.text-event{
  font-size: 16px !important;
}
.ant-tabs-tab > *{
    font-size:13px !important;
    overflow:hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  max-width: 120px;
  }
  .input-form > * {
    font-size: 13px !important;
}
.label > span{
  font-size: 16px !important;
}
.column_title {
  max-width: 100px;
}
.column_lang{
  min-width: 84px;
}
.column_action{
  min-width: 100px;
  margin:0 auto;
}
}
@media screen and (max-width:425px){
  .column_title {
    max-width: 80px;
  }
  .column_date{
    max-width: 95px !important;
  }
  .column_action{
    min-width: 140px;
    margin:0 auto;
  }
  
}
@media screen and (max-width:415px){
 
  .ant-tabs-tab > *{
    font-size:12px !important;
  }
  .input-form > * {
    font-size: 12px !important;
}
 /* .label span{
    font-size: 12px !important;
  } */
  .title{
    font-size: 16px;
    margin-left:0px !important;
    overflow:hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
  .column_title {
    max-width: 80px;
  }
  .column_date{
    max-width: 90px !important;
  }
  .column_action{
    min-width: 100px;
    margin:0 auto;
  }
  .ant-table-thead > tr {
    background-color: #fafafa;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    /* padding: 10px 10px !important; */
  }
  .ant-tabs-tab {
    padding: 9px !important;
}
}
@media screen and (max-width:390px){
  .column_title {
    max-width: 73px;
  }
  .column_date{
    max-width: 90px !important;
  }
  .column_action{
    min-width: 100px;
    margin:0 auto;
  }
  .ant-tabs-tab {
    padding: 9px !important;
}
}

@media screen and (max-width:380px){
  .title{
    font-size: 16px;
  }
  .ant-btn {
    padding: 4px 10px;
    font-size: 14px !important;
}
  
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 8px;
}
  .ant-tabs-tab > *{
    font-size:12px !important;
    overflow:hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  max-width: 100px;
  }
  .column_title {
    max-width: 73px;
  }
  .column_date{
    max-width: 95px !important;
  }
  .column_action{
    min-width: 80px;
    margin:0 auto;
  }
}

@media screen and (max-width:340){
  .ant-tabs-tab {
    padding: 8px !important;
}
  
}
.ant-modal-body {
  padding: 0 0 0 10px; 
  margin: 10px; 
}
.EditItem,
.DeleteItem{
  font-size: 12px;
  padding-left: 5px;
  padding-block: 20px !important;
}
.moreBtnMenu{
  position: absolute;
  display: block;
  z-index: 5;
  right: 5px;
  top: 5px;
  font-size: 28px;
  font-weight: bolder;
  color: white;
  background-color:rgba(0, 0, 0, 0.349);
  padding: 3px;
  border-radius: 50%;
transition: .5s all;
}
.moreBtnMenu:hover{
  background-color:rgba(0, 0, 0, 0.692);
  
}
.noMoreBtn{
  display: none;
}
.uploadDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.noImgAvailable{
    height: 100%;
    width: 100%;
     object-fit: cover;
    
    
  
}

.uploadBtn{
  border: rgba(128, 128, 128, 0.815) 2px solid;
  color: #1890ff;
  border-radius: 80%;
  padding: 7px ;
  position:relative;
  float: right;
  height:30px;
  width:30px;
  display:flex;
  align-items:center;
  justify-content: center;
  transition: .5s all ease-in-out;
 
  }
  .uploadBtn:hover{
      border: #1890ff 2px solid;
      color: #1890ff;
  }

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active{
  background-color: #fff;
}
.errorUrl{
  color: #ff4d4f;
  padding-top: 5px;
  margin-left: 26px;
  font-size: 14px;
  margin: 0 auto;
}

.custom-input{
   position: relative;
   cursor: pointer;
   width:20px;
   height: 24px;
}

.custom-input:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  left: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
}

.custom-input:checked:before {
  border-color: #1890ff;
  background-color: #1890ff;
}

.custom-input:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
  left: 6px;
}


.div_modalConfirmationTitle{
  display: flex;
  width: 92%;
  align-items: center;
  justify-content: center;   
  font-size: 14px;
  font-weight: 600;
}
.p_modalConfirmationContent{
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.custom-ant-modal {
  top: 50px;
}